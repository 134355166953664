<template>
  <div class="order-page">
    <div class="otc-title">
      <div class="left">{{ $t('otc.myOrder') }}</div>
      <div class="right" @click="$gbUtils.handleBack()">
        <img src="../../assets/close.png">
      </div>
    </div>
    <div class="order-list">
      <MyOrderBox v-for="item in ids" :key="item" :id="item"/>
      <van-empty v-if="!ids.length"/>
    </div>
  </div>
</template>

<script>
import contractOtc from '../../contracts/contractOtcV1'
import MyOrderBox from './components/MyOrderBox.vue'

export default {
  name: '',
  components: { MyOrderBox },
  data () {
    return {
      ids: []
    }
  },
  methods: {
    init () {
      this.getList()
    },
    async getList () {
      const resp = await contractOtc.getPendingIds()
      if (!resp.success) return
      this.ids = resp.result || []
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style scoped lang="scss">
.order-page {
  color: #fff;
  padding: 24px;
  box-sizing: border-box;
  .otc-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      font-size: 16px;
      font-weight: 500;
    }

    .right {
      color: rgba(255, 255, 255, 0.8);
      font-size: 14px;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}
</style>
