<template>
  <div class="order-box">
    <div class="order-type" :class="{'red': +orderInfo.direct === 1 }">{{ +orderInfo.direct === 1 ? $t('otc.hangBuy'): $t('otc.hangSale') }}</div>
    <div class="order-price">{{ $gbUtils.formatBalance(orderInfo.price || 0) }}</div>
    <div class="order-item">
      <div class="key">{{ $t('otc.totalNumbe') }}</div>
      <div class="value">{{ $gbUtils.formatBalance(orderInfo.total || 0)}} MAI</div>
    </div>
    <div class="order-item">
      <div class="key">{{ $t('otc.transactionsNum') }}</div>
      <div class="value">{{ $gbUtils.formatBalance((orderInfo.total - orderInfo.remain) || 0) }} MAI</div>
    </div>
    <div class="order-item">
      <div class="key">{{ $t('otc.remainingQuantity') }}</div>
      <div class="value">{{ $gbUtils.formatBalance(orderInfo.remain || 0)}} MAI</div>
    </div>
    <div class="order-btn">
      <VanButton class="btn"
       :loading="cancelLoading"
       @click="handleCancel"
       v-if="orderInfo.status === 0">{{$t('otc.cancellation')}}</VanButton>
      <span v-if="orderInfo.status === 1">{{$t('otc.completed')}}</span>
      <span v-if="orderInfo.status === 2">{{$t('otc.cancelled')}}</span>
    </div>
  </div>
</template>

<script>
import contractOtc from '../../../contracts/contractOtcV1'

export default {
  name: '',
  props: ['id'],
  data () {
    return {
      cancelLoading: false,
      orderInfo: {}
    }
  },
  methods: {
    async getOrderInfo () {
      const resp = await contractOtc.getOrderInfo(this.id)
      if (!resp.success) return
      this.orderInfo = {
        direct: +resp.result.direct,
        price: this.$web3.fromWei(resp.result.price),
        total: this.$web3.fromWei(resp.result.total),
        remain: this.$web3.fromWei(resp.result.remain),
        token: resp.result.token,
        sender: resp.result.sender,
        status: +resp.result.status
      }
    },
    async handleCancel () {
      this.cancelLoading = true
      const resp = await contractOtc.cancel(this.id)
      this.cancelLoading = false
      if (!resp.success) return
      this.getOrderInfo()
    }
  },
  mounted () {
    this.getOrderInfo()
  }
}
</script>

<style scoped lang="scss">
.order-box {
  background: #252525;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 25px;
  margin: 20px 0;
  position: relative;
  .order-type {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px 24px;
    background: #6667FF;
    border-radius: 0px 25px 0px 25px;
    font-size: 14px;
    &.red {
      background: #f25a5a;
    }
  }
  .order-price {
    font-size: 18px;
    color: #B2B3FF;
    line-height: 1.5em;
    font-weight: 500;
  }
  .order-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: normal;
    margin-top: 10px;
    .key {
      color: rgba(255,255,255,0.6);
    }
  }
  .order-btn {
    text-align: right;
    color: #B2B3FF;
    padding-top: 10px;
    font-size: 14px;
    .btn {
      height: 28px;
      border-radius: 28px 28px 28px 28px;
      border: 1px solid #6667FF!important;
      background: none;
      color: #B2B3FF;
    }
  }
}
</style>
